<template>
  <CRow>
    <CCol md="12">
      <CCard class="clapans">
        <CCardHeader>
        </CCardHeader>
        <CCardBody>
          <CCardBody>
            <div class="col-md-7">
              <CForm v-on:submit="submitForm()">
                <CInput type="hidden" v-model="traffic.id" horizontal/>
                <CInput type="text" v-model="traffic.number_of_birca" label="Номер бирки" horizontal/>
                <CInput type="text" v-model="traffic.weight" label="Вес" horizontal/>
                <CInput type="text" v-model="traffic.code_of_client" label="Код клиента" horizontal/>
                <CInput type="text" v-model="traffic.name_of_client" label="ФИО" horizontal/>
                <CInput type="text" v-model="traffic.items" label="Items" horizontal/>
                <CInput type="text" v-model="traffic.upakovka" @input="changeItem()" @change="changeItem()"
                        label="Упаковка" horizontal/>
                <CInput type="text" v-model="traffic.summa" label="Сумма" horizontal/>
                <CInput type="text" v-model="traffic.date_of_traffic" label="Дата" horizontal/>
                <CInput type="text" v-model="traffic.user_login" label="Логин клиента" horizontal/>
                <CInput type="text" v-model="traffic.paided" label="Оплачено" horizontal/>
                <CInput type="text" v-model="traffic.type_of_race" label="Тип" horizontal/>
                <CInput type="text" v-model="traffic.race_number" label="Рейс" horizontal/>
                <CInput type="text" v-model="traffic.price_per_kg" label="Цена за килограм" horizontal
                        @input="changeItem()"
                        @change="changeItem()"/>
                <CInput type="text" v-model="traffic.price_per_place" label="Цена за места" horizontal
                        @input="changeItem()"
                        @change="changeItem()"/>
                <CButton color="success" type="submit">
                  Сохранить
                </CButton>
              </CForm>
            </div>
          </CCardBody>
        </CCardBody>
      </CCard>
    </CCol>

    <div class="alert_block">
      <CAlert color="warning" closeButton v-if="warning">
        {{ alert_message }}
      </CAlert>
      <CAlert color="primary" closeButton v-if="success">
        {{ alert_message }}
      </CAlert>
    </div>
  </CRow>
</template>

<script>

const qs = require('qs')
const axios = require('axios')
export default {
  name: "MasterHubs",
  data() {
    return {
      traffic: {
        id: 0,
      },
      warning: false,
      success: false,
      alert_message: '',
      warningModal: false,
      isSelect: false,
      action_switcher: '',
      action_switcher_city: '',
      id: "",
      file_path: '',
      fileData: '',
      variables: '',
      tableItems: [],
      tableFields: [
        {key: "data", label: "Дата"},
        {key: "number_of_birca", label: "БРК "},
        {key: "weight", label: "Вес", _classes: "text-center"},
        {key: "code_of_client", label: "Код"},
        {key: "name_of_client", label: "ФИО"},
        {key: "naim", label: "Наим"},
        {key: "otpravka", label: "Отправка"},
        {key: "upk", label: "УПК"},
        {key: "sborn", label: "Сборн"},
        {key: "action", label: "Действия"},
      ],
    };
  },
  mounted() {
    this.getResults();
    this.getParams();
  },
  methods: {
    changeItem() {
      console.log(this.traffic);
      let summa = 0;
      if (this.traffic.upakovka != null && this.traffic.upakovka.length > 0) {
        summa = (parseFloat(this.traffic.weight) * parseFloat(this.traffic.price_per_kg)) + parseFloat(this.traffic.price_per_place) + parseFloat(this.traffic.upakovka);
      } else {
        summa = (parseFloat(this.traffic.weight) * parseFloat(this.traffic.price_per_kg)) + parseFloat(this.traffic.price_per_place)
      }
      let item_summa = summa;
      this.traffic.summa = Math.round(parseFloat(item_summa));
    },
    getParams() {
      let app = this;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
      axios.get('/auth/variables/list').then(response => {
        app.variables = response.data.variables;
      }).catch(error => {

      });
    },
    getResults() {
      let app = this;
      if (this.$route.params.id != 0) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
        axios.get('/auth/traffics/get/' + this.$route.params.id).then(response => {
          app.traffic = response.data.traffic;
        }).catch(error => {
          if (error.response.status == 401) {
            window.auth.logout();
          }
        });
      }
    },
    submitForm() {
      event.preventDefault();
      let app = this;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
      axios(
          {
            method: 'post',
            url: '/auth/traffics/update/' + this.$route.params.id,
            data: qs.stringify(this.traffic),
          })
          .then(({data}) => {
            app.getResults();
            app.warningModal = false;
            app.success = true;
            app.alert_message = 'Успешно сохранено';
            app.setting = {};
            setTimeout(function () {
              app.success = false;
            }, 3000)
            this.$router.go(-1);
          }).catch(function (error) {
        app.warning = true;
        app.alert_message = error.response.data.detail;
        setTimeout(function () {
          app.warning = false;
        }, 3000)

      })
    },

  },
};
</script>
<style>
.clapans .form-inline {
  margin-left: 15px;
}

.alert_block {
  position: fixed;
  right: 0;
  bottom: 0;
}
</style>
